<template>
    <span>
        ID : {{ this.fixedImei }}
    </span>
</template>

<script>
export default {
    props: ['imei'],
    computed: {
        fixedImei: function () {
            if(this.imei)
                return 'XXXX-' + this.imei.substring(11);
            else return ''
        }
    },
}
</script>