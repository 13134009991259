<template>
    <DefaultHeader />
    <div class="container-fluid">
        <div class="row d-flex justify-content-center flex-nowrap overflow-auto">
            <div class="col-11 card table-nowrap table-card">
                <div class="row d-flex justify-content-center fw-bold py-1">
                    <div class="col-2">Client</div>
                    <div class="col-6">
                        <div class="row d-flex justify-content-center">
                            <div class="col-3">Pack</div>
                            <div class="col-3">Quantité</div>
                            <div class="col-3">Dont télésurveillance</div>
                            <div class="col-3">Sous-totaux</div>
                        </div>
                    </div>
                    <div class="col-1 text-end">Total</div>
                </div>
                <div class="row d-flex justify-content-center border-top border-3 border-dark-subtle py-2"
                    v-for="(clientBilling, index) in this.clientsBillings"
                    :key="index">

                    <div class="col-2 align-items-center"><span>{{ clientBilling.client.name }}</span></div>

                    <div class="col-6">
                    <TableRow 
                        v-for="(billing, index) in clientBilling.billings"
                        :key="index"
                        :billing="billing" />
                    </div>

                    <div class="col-1 text-end">{{ clientBilling.clientTotal }}</div>
                </div>
                <div class="row d-flex justify-content-center border-top border-5 border-dark-subtle py-2 mt-3">

                    <div class="col-2">Total</div>
                    <div class="col-6">
                        <div class="row d-flex justify-content-center">
                            <div class="row d-flex justify-content-center border-bottom"
                                v-for="(subTotal, index) in this.subTotals"
                                :key="index">
                                <div class="col-3 d-inline-flex flex-wrap">
                                    {{ subTotal.name }}
                                </div>
                                <div class="col-3">
                                    {{ subTotal.packageAmount }} (unité {{ subTotal.packagePrice }})
                                </div>
                                <div class="col-3">
                                    {{ subTotal.remoteMonitAmount }} (unité {{ subTotal.remoteMonitPrice }})
                                </div>
                                <div class="col-3">
                                    {{ ((subTotal.packageAmount*subTotal.packagePrice).toFixed(2) * 100 + (subTotal.remoteMonitAmount*subTotal.remoteMonitPrice).toFixed(2) * 100) / 100 }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 text-end">{{ this.total }}</div>
                </div>
            </div>
        </div>
    </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import TableRow from '../components/billingPage/TableRow.vue'
import BillingService from '../services/billing.service.js'
import FormatHelper from '../helpers/format.helper.js'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        TableRow
    },
    data() {
        return {
            clientsBillings: {},
            total: 0,
            subTotals: []
        };
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            BillingService.getClientsBillings()
                .then(response => {
                    this.clientsBillings = FormatHelper.billingsResponseFormat(response.data);
                    this.subTotals = FormatHelper.subTotalsBillingsResponseFormat(response.data);
                    console.log(this.subTotals)
                    this.calcTotal();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        calcTotal() {
            this.clientsBillings.forEach(clientBilling => {
                this.total = (clientBilling.clientTotal.toFixed(2) * 100 + this.total.toFixed(2) * 100) / 100;
            });
        },
    }
}
</script>